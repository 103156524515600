import React, { useState, useEffect } from "react";
import { Segment, Container, Grid, Header, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useStore } from "../../contexts/store";
import "./styles.css";
import Visa from "../../assets/images/visa.png";
import MasterCard from "../../assets/images/mastercard.png";
import Aura from "../../assets/images/aura.png";
import Elo from "../../assets/images/elo.png";
import AmericanExpress from "../../assets/images/american-express.png";
import BanesCard from "../../assets/images/banes-card.png";
import Hiper from "../../assets/images/hiper.png";
import HiperCard from "../../assets/images/hiper-card.png";
import JBC from "../../assets/images/jbc.png";
import Boleto from "../../assets/images/boleto.jpg";
import Develop from '../Develop';

export default function Footer() {
  const { store } = useStore();
  const [showDevelopedBy, setShowDevelopedBy] = useState(true); 
  
  useEffect(() => {
    setShowDevelopedBy(store.name === "Acqua Menina" ? false : true);
  }, [store]);

  return (
    <Segment className="footer-container" vertical>
      <Container>
        {store && (
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <div className="footer-logo-container" style={{background: "var(--header-color, --secondary-background)", borderRadius: "4px"}}>
                <img
                  src={store.logo}
                  alt={store.name}
                  className="footer-logo"
                  
                />
                </div>
                <p>@ 2020 {store.name}. Todos os direitos reservados</p>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" content="SOBRE NÓS" />
                <List link>
                  <List.Item as={Link} to="/sobre">
                    Quem somos
                  </List.Item>
                  <List.Item as={Link} to="/contato">
                    Contato
                  </List.Item>
                  <List.Item as={Link} to="/duvidas-frequentes">
                    Dúvidas frequentes
                  </List.Item>
                  <List.Item as={Link} to="/politica">
                    Política de privacidade
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" content="REDES SOCIAIS" />
                <List link>
                  {store.instagram && (
                    <List.Item
                      as="a"
                      href={`https://instagram.com/${store.instagram}`}
                      target="_blank"
                    >
                      Instagram
                    </List.Item>
                  )}
                  {store.facebook && (
                    <List.Item
                      as="a"
                      href={`https://facebook.com/${store.facebook}`}
                      target="_blank"
                    >
                      Facebook
                    </List.Item>
                  )}
                  {store.twitter && (
                    <List.Item
                      as="a"
                      href={`https://twitter.com/${store.twitter}`}
                      target="_blank"
                    >
                      Twitter
                    </List.Item>
                  )}
                </List>
              </Grid.Column>
              <Grid.Column width={6} floated="right">
                <Header as="h5" content="BANDEIRAS" />
                <Grid.Row>
                  <img className="footer-flag-icon" alt="Visa" src={Visa} />
                  <img
                    className="footer-flag-icon"
                    alt="Visa"
                    src={MasterCard}
                  />
                  <img className="footer-flag-icon" alt="Visa" src={Elo} />
                  <img
                    className="footer-flag-icon"
                    alt="Visa"
                    src={BanesCard}
                  />
                  <img
                    className="footer-flag-icon"
                    alt="Visa"
                    src={AmericanExpress}
                  />
                </Grid.Row>
                <Grid.Row>
                  <img className="footer-flag-icon" alt="Visa" src={Hiper} />
                  <img
                    className="footer-flag-icon"
                    alt="Visa"
                    src={HiperCard}
                  />
                  <img className="footer-flag-icon" alt="Visa" src={Aura} />
                  <img className="footer-flag-icon" alt="Visa" src={JBC} />
                  <img className="footer-flag-icon" alt="Boleto" src={Boleto} />
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            {showDevelopedBy && <Develop />}
          </Grid>
        )}
      </Container>
    </Segment>
  );
}
