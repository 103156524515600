import React from "react";
import { Segment } from "semantic-ui-react";
import { useStore } from "../../contexts/store";
import "./styles.css";

export default function About() {
  const { store } = useStore();

  return (
    <div className="about-container">
      <Segment className="about-content-container">
        <h2>Quem somos</h2>
        <pre style={{ 
          fontFamily: "Open Sans", textAlign: "justify",
          whiteSpace: 'pre-wrap'
        }}>{store.description}</pre>
      </Segment>
    </div>
  );
}
